import React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Tag from "../../utils/tag"
import AuthorInfo from "./author-info"
import PostPreviewMeta from "./meta"
import PostPreviewTitle from "./title"
import ctl from "@netlify/classnames-template-literals"
import { Link } from "gatsby"

const BlogPostPreviewLarge = ({ post, light }) => {
  const wrapper_style = ctl(`
    ${light ? "text-white" : "text-dd-black-60"}
    `)

  const image_style = ctl(`
    w-full 
    md:h-[382px]
    h-44
    `)
  let show_image

  if (post.featuredImage) {
    show_image = (
      <GatsbyImage
        image={getImage(post.featuredImage.node.localFile)}
        alt={post.title}
        className={image_style}
      />
    )
  } else {
    show_image = (
      <StaticImage
        src="../../../images/post-preview.jpg"
        placeholder="blurred"
        className={image_style}
        alt="Image"
      />
    )
  }

  const postCategory = post.categories.nodes[0].name
  const postAuthor = post.extraPostDetails.author
  const postAuthorImage = post.extraPostDetails.authorImage?.mediaItemUrl
  return (
    <article className={wrapper_style}>
      <header className="relative text-zero">
        <Link to={`${post.uri}`}>
          {show_image}
        </Link>
        <div className="absolute  md:top-[19px] top-[10px] md:left-[22px] left-[12px]">
          <Tag name={postCategory} post_preview />
        </div>
      </header>

      <section className="grid md:grid-cols-3 grid-cols-1 md:pt-12 pt-2 place-items-start ">
        {/* Title and Image */}
        <div className="md:col-span-2 max-w-3xl flex md:flex-col flex-col-reverse">
          <PostPreviewTitle url={post.uri} name={post.title} light={light} />
          <PostPreviewMeta date={post.date} content={post.content} />
        </div>
        
        {/* Author Information */}
        <div className=" flex w-full items-center md:justify-end">
          <AuthorInfo name={postAuthor} image={postAuthorImage} />
        </div>
      </section>
      <footer></footer>
    </article>
  )
}

export default BlogPostPreviewLarge
