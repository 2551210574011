import React from "react"
import Tag from "../../utils/tag"
import PostPreviewMeta from "./meta"
import ctl from "@netlify/classnames-template-literals"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogPostPreview = ({ post, light, hide_category, dark }) => {
  const style = ctl(`
  ${light ? "text-white" : "text-dd-black-70"}
  `)

  const postCategory = post.categories ? post.categories.nodes[0].name : null

  const postAuthor = post.extraPostDetails?.author || "DearDesigner"
  const postAvatar = post.extraPostDetails?.authorImage?.mediaItemUrl
  const postFeaturedImage = post.featuredImage
    ? getImage(post.featuredImage.node.localFile)
    : null

  return (
    <article className={style}>
      <header>
        {postCategory && !hide_category ? (
          <Tag name={postCategory} outline={light && "light"} />
        ) : null}
        {post.date && (
          <div className="md:mt-[30px] ">
            <PostPreviewMeta date={post.date} />
          </div>
        )}
        <Link to={`${post.uri}`}>
          <p className="text-p16 md:mt-[22px] mt-[14px] font-medium ">
            {post.title}
          </p>
        </Link>

        <div
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
          className="md:block hidden"
        ></div>

        <Link to={`${post.uri}`}>
          {postFeaturedImage ? (
            <GatsbyImage image={postFeaturedImage} className="h-28 mt-6" />
          ) : null}
        </Link>
      </header>
    </article>
  )
}

export default BlogPostPreview
