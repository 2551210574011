import { graphql } from "gatsby"
import React from "react"
import PagesLayout from "../../layout/pages"
import ColumnPageHeader from "./header.js"
import BlogPostPreview from "../../blog/preview"
import BlogPostPreviewLarge from "../../blog/preview/large"
import ctl from "@netlify/classnames-template-literals"

export const category = graphql`
  query columnPageQuery($id: String) {
    wpCategory(id: { eq: $id }) {
      name
      description
    }

    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          uri
          date(formatString: "DD MMMM YYYY")
          excerpt
          
          extraPostDetails{
            author
            authorImage {
              mediaItemUrl
            }
          }

          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                }
              }
            }
          }

          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`

const columnPostsStyle = ctl(
  `grid lg:grid-cols-2 grid-cols-1 gap-y-10 gap-x-20`
)
const ColumnPage = ({ data }) => {
  const column = data.wpCategory
  const allColumnPosts = data.allWpPost.edges

  const largePost = allColumnPosts[0]?.node

  const showColumnPosts = allColumnPosts
    .filter((_, index) => index > 0)
    .map((post, index) => (
      <BlogPostPreview key={index} post={post.node} hide_category />
    ))
  return (
    <PagesLayout>
      <ColumnPageHeader name={column.name} description={column.description} />

      {
        largePost && (
          <section className="my-12">
            <BlogPostPreviewLarge post={largePost} />
          </section>
        )
      }
      <hr />
      <section className={columnPostsStyle}>{showColumnPosts}</section>
    </PagesLayout>
  )
}

export default ColumnPage
