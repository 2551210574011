import React from "react"

const CategoryLineIcon = () => {
  return (
    <svg
      width="34"
      height="1"
      viewBox="0 0 34 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="4.37114e-08"
        y1="0.5"
        x2="33.375"
        y2="0.500003"
        stroke="#494949"
      />
    </svg>
  )
}

export default CategoryLineIcon
