import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const PostPreviewTitle = ({ name, url, light }) => {
  return (
    <Link to={url}>
      <h3 className={`md:h3 h4 md:mb-0 mb-[31px] ${light && "!text-white"}`}>
        {name}
      </h3>
    </Link>
  )
}

PostPreviewTitle.propTypes = {
  name: PropTypes.string.isRequired,
}

export default PostPreviewTitle
