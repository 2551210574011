import React from "react"
import PropTypes from "prop-types"
import ctl from "@netlify/classnames-template-literals"

const Tag = ({ name, post_preview, outline, addon_style }) => {
  const tag_style = ctl(`
  text-dd-black-70 
  md:px-4 
  px-3 
  py-2 
  uppercase 
  leading-none 
  md:rounded 
  rounded-sm 
  tracking-widest 
  md:text-p12 
  text-p8 
  font-normal 
  ${post_preview && "bg-white bg-opacity-70"}
 border-[0.5px] 
  ${
    outline === "light"
      ? "border-white  !text-white"
      : "border-dd-black-30 !text-dd-black-50"
  }
  ${addon_style}
    `)
  return <span className={tag_style}>{name}</span>
}

Tag.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Tag
