import React from "react"
import PropTypes from "prop-types"
import readingTime from "reading-time"

const PostPreviewMeta = ({ date, content }) => {
  const READING_TIME = content && Math.round(readingTime(content).minutes)

  return (
    <div className="flex gap-x-[68px] text-[10px]  w-full md:text-p12 uppercase md:mt-[28px] mt-[20px] mb-0 tracking-widest">
      {date && <p className="mt-0 ">{date}</p>}
      {content && <p className="mt-0">{READING_TIME} Mins Read</p>}
    </div>
  )
}

PostPreviewMeta.propTypes = {
  date: PropTypes.string.isRequired,
}

export default PostPreviewMeta
