import React from "react"
import { CategoryLineIcon } from "../../../../icons"
import ctl from "@netlify/classnames-template-literals"

const ColumnPageHeader = ({ name, description }) => {
  const headerTitleStyle = ctl(`flex 
  text-p16 
  font-medium 
  tracking-widest 
  uppercase
  items-center
  `)

  const headerTitleNameStyle = ctl(`ml-3`)
  return (
    <header>
      <div className={headerTitleStyle}>
        <CategoryLineIcon />
        <span className={headerTitleNameStyle}>{name}</span>
      </div>
      <p dangerouslySetInnerHTML={{ __html: description }}></p>
    </header>
  )
}

export default ColumnPageHeader
